import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    NavigationExtras,
    CanLoad, Route
} from '@angular/router';

import { AuthService } from './auth.service';


/**
 * A guard which should prevent loading or displaying a page only when the logged in user has a full TSB licence (has both CM and CPD).
 * This means that the user will be able to (1) access the page even if not logged in and (2) access the page with any licence except the full
 * licence.
 */
@Injectable()
export class StopOnlyFullLicenceGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private router: Router, private authService: AuthService) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        let url: string = state.url;
        return this.checkCredentials(url);
    }


    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        return this.canActivate(route, state);
    }


    canLoad(route: Route): boolean {

        let url = `/${route.path}`;
        return this.checkCredentials(url);
    }


    /**
     * Checks whether the user is logged in and if so, whether it has a full licence or not. Will return true when the user is not logged in, or when logged in, only if the licence is not the full TSB licence. If the logged in user has a full TSB licence (meaning both CM and CPD), it should navigate the user back to the home-page and return false.
     * @param url The URL to check.
     */
    checkCredentials(url: string): boolean {

        if (this.authService.authenticated &&
                this.authService.hasPolicyManager &&
                this.authService.hasComplianceStandard) {
            this.router.navigate(['/home']);
            return false;
        }
        else {
            return true;
        }
    }
}
