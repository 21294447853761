import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { Router } from '@angular/router';

@Injectable()
export class HeaderService extends BaseService {

    constructor(protected http: HttpClient, private router: Router) {
        super(http);
        router.events.subscribe(() => {
            this.hideHeaderSubject.next(false);
        })
    }

    private hideHeaderSubject = new BehaviorSubject<boolean>(false);
    hideHeader$ = this.hideHeaderSubject.asObservable();

    private cachedHeaderData: any;
    private headerDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    getHeader(): Observable<any> {
        return this.http.get('/api/header/GetHeaderData').pipe(
            tap(data => {
                this.cachedHeaderData = data;
                this.headerDataSubject.next(data);
            })
        );
    }

    getCachedHeaderData(): Observable<any> {
        if (this.cachedHeaderData) {
            console.log('returning cached header data');
            return this.headerDataSubject.asObservable();
        } else {
            return this.getHeader()
        }
    }

    updateHideHeader(value: boolean): void {
        this.hideHeaderSubject.next(value);
    }
}
