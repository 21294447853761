<div [class]="headerOverlayable ? 'header-container-overlayable' : 'header-container'" *ngIf="!hideHeader">
    <div id="top-section">
        <div id="top-left-desktop" class="mobile-header">
            <mobile-nav [mobileNavData]="headerData"></mobile-nav>
            <header-logo></header-logo>
            <header-account id="mobile-account" *ngIf="headerData" [loggedIn]="authService.loggedIn" [myAccount]="headerData.MyAccount" [myDocumentsToAction]="headerData.MyDocumentsToAction"></header-account>
        </div>
        <div id="top-right-desktop">
            <div id="search-account">
                <header-search></header-search>
                <a class="btn btn-orange" *ngIf="showMemberButton" [routerLink]="memberButtonUrl">
                    <span class="material-symbols-outlined">
                        verified_user
                    </span>
                    {{memberText}}
                </a>
                <header-account *ngIf="headerData" [loggedIn]="authService.loggedIn" [myAccount]="headerData.MyAccount" [myDocumentsToAction]="headerData.MyDocumentsToAction"></header-account>
            </div>
            <div *ngIf="headerData">
                <header-toolbar [toolbarData]="headerData.HeaderToolbars"></header-toolbar>
            </div>
        </div>
    </div>
    <header-category-links *ngIf="headerData" [categories]="headerData.HeaderCategories"></header-category-links>
</div>


