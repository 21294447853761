<div id="top" class="mainwrapper">
    <header></header>
    <mat-sidenav-container class="side-nav-container" [ngClass]="bodyClass">

        <mat-sidenav #sidenav mode="over" opened="false" class="example-sidenav" [fixedInViewport]="false" [fixedTopGap]="100">
        </mat-sidenav>

        <mat-sidenav-content id="body-content" class="sidenav-content">
            <div [class]="routerWrapper">
                <router-outlet (activate)="onActivate($event)"></router-outlet>
            </div>
            <footer *ngIf="showFooter"></footer>
        </mat-sidenav-content>

    </mat-sidenav-container>
    <nps-collection></nps-collection>
    <div id="iframe-logouts"></div>
</div>
