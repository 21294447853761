<div class="search-bar-container" (click)="searchBarClicked()">
    <img src="../../../../Img/icons/material/search_navy.svg" />
    <input [(ngModel)]="searchText" [placeholder]="searchPlaceholder" matInput (keyup)="searchTextChanged($event)"
           (focus)="searchFocused(true)" (blur)="searchFocused(false)" autocomplete="off" class="header-search" />
    <div id="search-suggestions-container" [ngStyle]="suggestionsStyle()">
        <div *ngIf="showPopularSearchResults && autocompleteResults.length == 0 || showPopularSearchResults && searchText.length <= 2">
            <h2 class="search-header">TOP SEARCHES</h2>
            <div class="autocomplete-suggestion" *ngFor="let popularSearchItem of popularSearches; let last = last"
                 (click)="selectSuggestion(popularSearchItem.ItemName)" [ngClass]="{ last: last }">
                <img src="../../../../Img/icons/material/search_light_navy.svg" />
                <p class="autocomplete-text">{{popularSearchItem.ItemName}}</p>
            </div>
        </div>

        <div class="desktop-nav" *ngIf="autocompleteResults && autocompleteResults.length > 0 && searchText.length > 2">
            <h2 class="search-header">SUGGESTED SEARCHES</h2>
            <div class="autocomplete-suggestion" *ngFor="let searchResult of autocompleteResults"
                 (click)="selectSuggestion(searchResult.text)">
                <img src="../../../../Img/icons/material/search_light_navy.svg" />
                <p class="autocomplete-text">{{searchResult.text}}</p>
            </div>
        </div>

        <div *ngIf="candidateResults && candidateResults.length > 0 && searchText.length > 2">
            <h2 class="search-header desktop-nav">TOP MATCHES</h2>
            <div class="search-suggestion-group">
                <div class="search-suggestion-item" *ngFor="let content of candidateResults; let last = last"
                     #tooltip="matTooltip" matTooltip="{{content.description}}"
                     matTooltipClass="searchResultTooltip"
                     (click)="viewResultCandidate(content)">
                    <div class="search-content-container">
                        <span class="search-content-category">{{content.category}}</span>
                        <span>{{newOrUpdated(content)}}</span>
                    </div>
                    <p class="search-content-title">{{content.title}}</p>
                    <p class="search-content-description">{{truncatedDescription(content.description)}}</p>
                    <div class="bottom-line" [ngClass]="{ last: last }"></div>
                </div>
                <div class="bottom-line"></div>
                <div class="see-more-group" (click)="search()">
                    <p>See more results</p>
                    <img src="../../../../Img/icons/material/arrow_forward_orange.svg" />
                </div>
            </div>
        </div>
    </div>
</div>
