<div><strong>General Terms and Conditions</strong></div>
<div>&nbsp;</div>
<p>Scroll to the bottom to accept our terms and conditions</p>
<div>&nbsp;</div>
<p>Welcome to our Website. We’re very happy you have chosen to subscribe to our Services, but before you start using them, we need you to read and accept these Terms.</p>
<p>These Terms set out both yours and our legal rights and obligations, so please do take the time to read them carefully. We do however have the right to change these Terms at any time, and where we do so, we will make every effort to communicate these changes to you via email or notification via the Website. Please note it is your obligation to ensure that you have read, understood and agree to the most recent Terms available on the Website.</p>
<p>If you have any queries about these Terms, please do get in touch with us at <a href="mailto:info@schoolbus.co.uk" style="cursor: pointer;">info@schoolbus.co.uk</a> and we’d be happy to advise accordingly.</p>
<p>By registering to use the Service you acknowledge that you have read and understood these Terms and have the authority to act on behalf of any person for whom you are using the Service. You are deemed to have agreed to these Terms on behalf of any entity for whom you use the Service. The Order Form together with these Terms constitute the agreement between us.</p>
<p>All capitalised terms have the meanings provided to them in clause 14 (as set out at the end of these Terms). Where we refer to we/us/our/Hub4Leaders, we are referring to Hub4Leaders Limited, (company number 08368437).</p>
<br />
<strong>1. User subscription</strong>
<p>1.1	We refer to those that subscribe to our Service as the Customer. The Customer is the party referred to on the Order Form that purchases the User Subscription from us. The Customer could for example be a school, MAT or a LA.</p>
<p>1.2	Each Customer must appoint a Master User who will act as the main point of contact with Hub4Leaders and will control the Customer’s access to the Services, granting and managing access for Authorised Users.</p>
<p>1.3	In the event of the Customer being a MAT, LA, Diocese of federation, they must ensure a Master User is appointed for each of the schools they have purchased a User Subscription.</p>
<p>1.4	Authorised Users are those persons that the Customer permits to use the Service (for example, an employee, governor, trustee, director of the Customer).</p>
<p>1.5	Each Customer is fully responsible for controlling how its subscription to the Service is managed and to ensure that those accessing the Service do so in compliance with these Terms. Subject to the Customer complying with its obligations under these Terms, Hub4Leaders grant to the Customer a non-exclusive, non-transferable right, without the right to grant sublicences, to permit the Authorised Users to use the Services and the Documentation during the Subscription Term solely for the Customer's internal business operations.</p>
<p>1.6	In relation to the Authorised Users, the Customer undertakes that:</p>
<p style="margin-left: 10px;">a) it will not allow any User Subscription to be used by more than one individual Authorised User unless it has been reassigned to another individual Authorised User, in which case the prior Authorised User shall no longer have any right to access or use the Services and/or Documentation;</p>
<p style="margin-left: 10px;">b) it will not allow a person to become an Authorised User who is not employed by the Customer, or in the absence of such an employment relationship, is not a governor, trustee, director of the Customer;</p>
<p style="margin-left: 10px;">c) it will not grant, or allow to be granted, access to, or the right for, anyone other than an Authorised User to access the Services and/or the Documentation;</p>
<p style="margin-left: 10px;">d) each Authorised User shall keep a secure password for its use of the Services and Documentation and that each Authorised User shall keep its password confidential at all times; and</p>
<p style="margin-left: 10px;">e) it will maintain accurate records of all Authorised Users and provide us with access to such records (as and when requested by us) for auditing. If an audit reveals any unauthorised access, then that access will be disabled without prejudice to our other rights. If an audit reveals that the Customer has underpaid Subscription Fees, then without prejudice to our other rights, the Customer shall pay to Hub4Leaders an amount equal to such underpayment as calculated in accordance with the prices set out in the Order Form within 10 Business Days of the date of the relevant audit.</p>
<br />
<p>1.7	The Customer shall not, and shall ensure that the Authorised Users do not, access, store, distribute or transmit any Viruses, or any material during the course of its use of the Services that:</p>
<p style="margin-left: 10px;">a) is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive;</p>
<p style="margin-left: 10px;">b) facilitates illegal activity;</p>
<p style="margin-left: 10px;">c) depicts sexually explicit images;</p>
<p style="margin-left: 10px;">d) promotes unlawful violence;</p>
<p style="margin-left: 10px;">e) is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; or</p>
<p style="margin-left: 10px;">f) is otherwise illegal or causes damage or injury to any person or property;</p>
<br />
<p>and Hub4Leaders reserves the right, without liability or prejudice to its other rights to the Customer, to disable the Customer's access to any material that breaches the provisions of this clause.</p>
<br />
<p>1.8  The Customer shall not, and shall ensure that the Authorised Users do not (except as may be allowed by any applicable law which is incapable of exclusion by agreement between the parties and except to the extent expressly permitted under these Terms):</p>
<p style="margin-left: 10px;">a) attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Software and/or Documentation (as applicable) in any form or media or by any means; or</p>
<p style="margin-left: 10px;">b) attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Software; or</p>
<p style="margin-left: 10px;">c) access all or any part of the Services and Documentation in order to build a product or service which competes with the Services and/or the Documentation; or</p>
<p style="margin-left: 10px;">d) use the Services and/or Documentation to provide services to third parties; or</p>
<p style="margin-left: 10px;">e) license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services and/or Documentation available to any third party except the Authorised Users, or</p>
<p style="margin-left: 10px;">f) attempt to obtain, or assist third parties in obtaining, access to the Services and/or Documentation, other than as provided under this clause 1.</p>
<br />
<strong>2. Duration of User Subscription</strong>
<p>2.1	The subscription to the Service shall commence on the date specified by us, following receipt 	of the completed Order Form. We call this date the Effective Date.</p>
<p>2.2	The User Subscription shall continue for the period specified in the Order Form, being the Initial 	Subscription Term. Upon expiry of the Initial Subscription Term, the User Subscription shall 	automatically renew for successive periods of 12 months (each a Renewal Period), unless:</p>
<p style="margin-left: 10px;">(a) either party notifies the other party of termination, in writing, at least 30 days before the end of the Initial Subscription Term or any Renewal Period, in which case this agreement shall 	terminate upon the expiry of the applicable Initial Subscription Term or Renewal Period; or</p>
<p style="margin-left: 10px;">(b) it is otherwise terminated in accordance with the provisions of this agreement; and the Initial Subscription Term together with any subsequent Renewal Periods shall constitute the Subscription Term.</p>
<p>2.3	Each subscription shall provide the Customer with access to the Service and its associated Documentation, for the duration of the Subscription Term.</p>
<p>2.4  In the event that the Customer is a MAT, then the Customer must purchase a User Subscription for each of the schools within their trust that require access to the Service. In addition, the MAT must also purchase an additional Trust User Subscription for the trust’s leadership to gain access to the Service.  In the event that the Customer is a Local Authority, Diocese, Federation or a Traded Services, the Customer must purchase a User Subscription for each of the schools recorded in the purchase agreement with Hub4leaders.  Please note that under no circumstance can the Customer use the same User Subscription across a multiple number of schools.</p>
<br />
<strong>3.  The Service</strong>
<p>3.1  Hub4Leaders shall, during the Subscription Term provide the Services and Documentation to the Customer on and subject to the provisions of these Terms.</p>
<p>3.2  Hub4Leaders shall use commercially reasonable endeavours to make the Services available 24 hours a day, seven days a week, except for any unscheduled maintenance, whether performed during or outside of Normal Business Hours, provided that Hub4Leaders has used reasonable endeavours to give the Customer at least 2 normal business hours' notice in advance.</p>
<p>3.3  Should the Customer require any support with the Services, the Customer may contact Hub4Leaders via telephone, email or using the online chat facility available on the Website. Hub4Leader will endeavour to respond to the Customer as soon as it is reasonably possible to do so. The Customer may also access and use the “Content Suggestion Service” available on the Website.</p>
<br />
<strong>4.  The Free Trial Period</strong>
<p>4.1  Individuals can request a free trial of the Service by completing the free trial subscription process set out on the Website. If a school does not already have a subscription registered on the Service, then the individual is granted access to the Service for a limited period of time or until the allocated amount of document downloads is reached before the free trial subscription is disabled, both instances are at the discretion of Hub4Leaders. A school with an existing free trial account cannot undertake an additional free trial account.</p>
<p>4.2  If the Service is purchased directly after a free trial, then the Subscription Term begins on the Effective Date (as referred to in clause 2.1 above). The Effective Date overrides the free trial subscription date.</p>
<p>4.3  If the Service is not purchased after the free trial subscription expires, then the free trial subscription shall be disabled, and a record of the requesting user’s details shall be retained by Hub4Leaders to ensure that the same customer does not have access to a free trial period again.</p>
<br />
<strong>5.  Data and Privacy</strong>
<p>5.1  The Customer shall own all right, title and interest in and to all of the Customer Data and shall have sole responsibility for the legality, reliability, integrity, accuracy and quality of the Customer Data.</p>
<p>5.2  Hub4Leaders has in place a disaster recovery plan which includes the backing up of data utilised as part of the Services, including Customer Data. This back-up is for our business purposes and does not remove the requirement for the Customer to have a suitable disaster recovery plan and back-up policy for its own data, including any Customer Data. Hub4Leaders gives no warranties as to the accuracy, completeness, or up-to-datedness of any of its back-ups at any time. In the event of any loss or damage to the Customer Data, such loss or damage arising due to the direct action of Hub4Leaders, the Customer’s sole and exclusive remedy shall be for Hub4Leaders to use reasonable commercial endeavours to restore the lost or damaged Customer Data from the latest back-up of such Customer Data held by Hub4Leaders.</p>
<p>5.3  Hub4Leaders shall, in providing the Services, comply with its Privacy Policy, available by clicking the link in the footer relating to the privacy and security of the Customer Data. We reserve the right to amend our Privacy Policy at any time.</p>
<p>5.4  If Hub4Leaders processes any personal data on the Customer's behalf when performing its obligations under these Terms, the parties record their intention that the Customer shall be the data controller and Hub4Leaders shall be a data processor and in any such case:</p>
<p style="margin-left: 10px;">a) some of our external third parties are based outside the European Economic Area (EEA) so their processing of your personal data will involve a transfer of data outside the EEA; and</p>
<p style="margin-left: 10px;">b) whenever we transfer your personal data out of the EEA, we will ensure it is done so in compliance with the Data Protection Legislation.</p>
<br />
<p>5.5  Hub4Leaders confirms that, when processing personal data, it:</p>
<p style="margin-left: 10px;">a) does so only in accordance with the terms of this agreement and any lawful instructions reasonably given by the Customer from time to time;</p>
<p style="margin-left: 10px;">b) ensures that all personnel who have access to and/or process personal data are obliged to keep the personal data confidential;</p>
<p style="margin-left: 10px;">c) takes all appropriate technical and organisational measures to protect against unauthorised or unlawful processing of personal data and against accidental loss or destruction of, or damage to, the personal data;</p>
<p style="margin-left: 10px;">d) shall assist the Customer with any requests for exercising the rights of any data subject that is employed by the Customer, or in the absence of such an employment relationship, is a governor, trustee, director of the Customer;</p>
<p style="margin-left: 10px;">e) shall assist the Customer in ensuring compliance with the obligations pursuant to the security of processing, notification of a personal data breach to the ICO, communication of a personal data breach to the data subject, data protection impact assessments (DPIA), and to consulting the ICO prior to processing where a DPIA indicates that the processing would result in a high risk in the absence of measures taken by the Customer to mitigate the risk;</p>
<p style="margin-left: 10px;">f) shall at the choice of the Customer, delete or return all the personal data to the Customer after the end of the provision of Services relating to processing, and delete existing copies unless applicable law require storage of the personal data;</p>
<p style="margin-left: 10px;">g) shall make available to the Customer all information necessary to demonstrate compliance with the obligations outlined in this clause 5.5 and allow for and contribute to audits, including inspections, conducted by the Customer or another auditor mandated by the Customer.</p>
<p>5.6  The Customer shall ensure that the relevant third parties have been informed of, and have given their consent to the use, processing, and transfer of their personal data to Hub4Leaders as required by the Data Protection Legislation.</p>
<p>5.7  Each party shall take appropriate technical and organisational measures against unauthorised or unlawful processing of the personal data or its accidental loss, destruction or damage.</p>
<p>5.8  When disclosing personal data to Hub4Leaders, the Customer warrants that:</p>
<p style="margin-left: 10px;">a) it has permission to pass any such personal data to Hub4Leaders;</p>
<p style="margin-left: 10px;">b) it has permission to require Hub4Leaders to process any personal data in any way in which the Customer has requested Hub4Leaders to process such personal data;</p>
<p style="margin-left: 10px;">c) it has in place suitable systems and processes to ensure the personal data provided to Hub4Leaders is accurate, up to date, and relevant; and</p>
<p style="margin-left: 10px;">d) it is not breaking any laws by passing the personal data to Hub4Leaders, including without limitation, its obligations under the Data Protection Legislation.</p>
<br />
<strong>6.  Charges and Payments</strong>
<p>6.1  User Subscriptions are annual and shall commence on the Effective Date.</p>
<p>6.2  The Subscription Fee is as set out in the Order Form. Payment of the Subscription Fee entitles the Authorised Users to access and use the Services and the Documentation for the duration of the Subscription Term.</p>
<p>6.3  The Customer shall pay the Subscription Fees to Hub4Leaders for the Subscription Term on the receipt of the invoice from Hub4Leaders and in accordance with this clause and the Order Form.</p>
<p>6.4  On completion of the Order Form, Hub4Leaders shall invoice the Customer in accordance with the terms outlined in the Order Form, or in the absence of such terms, Hub4Leaders shall invoice the customer 30 days prior to each anniversary of the Effective Date for the Subscription Fees payable in respect of the next Renewal Period.</p>
<p>6.5  The Customer shall pay each invoice within 30 days after the date of such invoice.</p>
<p>6.6  In the event that a MAT or a LA wish to add new schools to subscribe to the Service during the Subscription Term, a pro-rated cost will be agreed by Hub4Leaders and charged to the Customer.</p>
<p>6.7  If Hub4Leaders does not receive payment of any sums due to it by the due date, then Hub4Leaders reserve the right (without prejudice to any other rights or remedies it may have) to disable the Customer’s account until payment is received and/or to terminate the Customer’s account.</p>
<p>6.8  All amounts and fees stated or referred to in the Order Form or invoice are exclusive of VAT (which shall be invoiced and payable by the Customer in addition to the Subscription Fee).</p>
<p>6.9  Hub4Leaders reserve the right to increase the Subscription Fee on each 12-month anniversary of the Effective Date by providing the Customer with prior notice of the increase.</p>
<br />
<strong>7.  Termination</strong>
<p>7.1	Customers can terminate their User Subscription by providing Hub4Leaders with 30 days’ written notice prior to the anniversary of the Effective Date, paying all relevant Subscription 	Fees up to and including the day of termination. Failure to provide notice in accordance with 	this clause shall mean the User Subscription shall continue for an additional Renewal Period.</p>
<p>7.2	Hub4Leaders may terminate a User Subscription at any time, without reason, by providing the Customer with 30 days’ written notice.</p>
<p>7.3	Hub4Leaders may (without prejudice to any other rights or remedies it may have) terminate or suspend a User Subscription immediately on notice to the Customer if the Customer:</p>
<p style="margin-left: 10px;">(a) the Customer breaches any provision of these Terms and does not remedy the breach within 30 days after receiving notice to do so;</p>
<p style="margin-left: 10px;">(b) breaches any of provision of these Terms which is not capable of being remedied;</p>
<p style="margin-left: 10px;">(c) fails to pay the Subscription Fees (or any portion of them) by the due date for payment; or</p>
<p style="margin-left: 10px;">(d) is subject to an Insolvency Event.</p>
<p>7.4	No refund is due to the Customer if they terminate access to the User Subscription or, Hub4Leaders terminates in accordance with clause 7.3.</p>
<p>7.5	On termination of the User Subscription, access to the Service and the Documentation shall automatically cease for all Authorised Users. However, the Customer will have access to its user account for a period of 7 days (following the date of termination) to remove its documentation and data hosted on the Service.</p>
<p>7.6	Hub4Leaders will retain the user data in accordance with the terms of its data retention policy (as the same may be amended from time to time).</p>
<br />
<strong>8.  Third party providers</strong>
<p>8.1	Hub4Leaders uses a number of third-party on-line services to provide the Service to our Customers.</p>
<p>8.2	Hub4Leaders subscription Service is developed and maintained by Hub4Leaders and hosted on third party cloud services.</p>
<p>8.3	Hub4Leaders process the user data, i.e. the names and email addresses provided by the customer and transfer them to the third party on-line services to support marketing, customer communications, account finance and administration.</p>
<p>8.4	Hub4Leaders will use external sources to contribute to the information offered within the Service.</p>
<p>8.5	Hub4Leaders Services and Documentation will only refer to external sources such as the DfE or Ofsted.</p>
<p>8.6	Hub4Leaders does not refer to or approve any third-party website nor the content of any of the third-party website via the Services.</p>
<br />
<strong>9.  Proprietary rights</strong>
<p>9.1	The Customer acknowledges and agrees that Hub4Leaders and/or its licensors own all intellectual property rights in the Services and the Documentation. Except as expressly stated herein, this agreement does not grant the Customer any rights to, under or in, any patents, copyright, database right, trade secrets, trade names, trademarks (whether registered or unregistered), or any other rights or licences in respect of the Services or the Documentation.</p>
<p>9.2	Hub4Leaders confirms that it has all the rights in relation to the Services and the Documentation that are necessary to grant all the rights it purports to grant under, and in accordance with, the terms of this agreement.</p>
<p>9.3	The Customer shall prevent any unauthorised access to, or use of, the Services and/or the Documentation and, in the event of any such unauthorised access or use, promptly notify Hub4Leaders.</p>
<p>9.4	The rights provided under this clause 9 are granted to the Customer only and shall not be considered granted to any other school or academy that is a member of a MAT that the Customer belongs to.</p>
<br />
<strong>10. Indemnity and Liability</strong>
<p>10.1	The Customer indemnify Hub4Leaders against all losses, costs (including legal costs), expenses, demands or liability that are incurred arising out of, or in connection with, a third-party claim against Hub4Leaders relating to the Customer’s (and for the avoidance of doubt, any Authorised User’s) use of our services or any third-party product (except as far as Hub4Leaders are at fault).</p>
<p>10.2	Except as expressly and specifically provided in these Terms:</p>
<p style="margin-left: 10px;">a) the Customer assumes sole responsibility for results obtained from the use of the Services and the Documentation by the Customer, and for conclusions drawn from such use. Hub4Leaders shall have no liability for any damage caused by errors or omissions in any information, instructions or scripts provided to Hub4Leaders by the Customer in connection with the Services, or any actions taken by Hub4Leaders at the Customer's direction;</p>
<p style="margin-left: 10px;">b) all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from this agreement; and</p>
<p style="margin-left: 10px;">c) the Services and the Documentation are provided to the Customer on an "as is" basis.</p>
<p>10.3 Nothing in these Terms excludes the liability of Hub4Leaders:</p>
<p style="margin-left: 10px;">a) for death or personal injury caused by Hub4Leaders 's negligence; or</p>
<p style="margin-left: 10px;">b) for fraud or fraudulent misrepresentation.</p>
<p>10.4 Subject to clause 10.2 and clause 10.3:</p>
<p style="margin-left: 10px;">a) Hub4Leaders shall not be liable whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation, restitution, or otherwise for any loss of profits, loss of business, depletion of goodwill and/or similar losses, or loss or corruption of data or information, or pure economic loss, or for any special, indirect, or consequential loss, costs, damages, charges, or expenses however arising under this agreement; and</p>
<p style="margin-left: 10px;">b) Hub4Leaders 's total aggregate liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of this agreement shall be limited to the total Subscription Fees paid for the User Subscriptions during the 12 months immediately preceding the date on which the claim arose.</p>
<br />
<strong>11. General</strong>
<p>11.1	Hub4Leaders shall have no liability to the Customer under this agreement if it is prevented from or delayed in performing its obligations under this agreement, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control, including, without limitation, strikes, lock-outs or other industrial disputes (whether involving the workforce of Hub4Leaders or any other party), failure of a utility service or transport or telecommunications network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm or default of suppliers or sub-contractors, provided that the Customer is notified of such an event and its expected duration.</p>
<p>11.2	If there is an inconsistency between any of the provisions in the main body of this agreement and anything contained with the Order Form, the provisions in the main body of this agreement shall prevail.</p>
<p>11.3	No failure or delay by a party to exercise any right or remedy provided under this agreement or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy.</p>
<p>11.4	Except as expressly provided in this agreement, the rights and remedies provided under this agreement are in addition to, and not exclusive of, any rights or remedies provided by law.</p>
<p>11.5 If any provision (or part of a provision) of this agreement is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force.</p>
<p>11.6 If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of it were deleted, the provision shall apply with whatever modification is necessary to give effect to the commercial intention of the parties.</p>
<p>11.7 This agreement constitutes the entire agreement between the parties and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.</p>
<p>11.8 Each party acknowledges that in entering into this agreement it does not rely on, and shall have no remedies in respect of, any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in this agreement.</p>
<p>11.9 Each party agrees that it shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in this agreement. Nothing in this clause shall limit or exclude any liability for fraud.</p>
<p>11.10    The Customer shall not, without the prior written consent of Hub4Leaders, assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under this agreement. Hub4Leaders may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under this agreement.</p>
<p>11.11    Nothing in this agreement is intended to or shall operate to create a partnership between the parties, or authorise either party to act as agent for the other, and neither party shall have the authority to act in the name or on behalf of or otherwise to bind the other in any way (including, but not limited to, the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power).</p>
<p>11.12    This agreement does not confer any rights on any person or party (other than the parties to this agreement and, where applicable, their successors and permitted assigns) pursuant to the Contracts (Rights of Third Parties) Act 1999.</p>
<br />
<strong>12. Notices</strong>
<p>12.1	Any notice required to be given under this agreement must be in writing by email or written letter and agreed on confirmation of receipt.</p>
<p>12.2	A notice delivered by hand shall be deemed to have been received when delivered (or if delivery is not in business hours, at 9 am on the first Business Day following delivery). A correctly addressed notice sent by pre-paid first-class post or recorded delivery post shall be deemed to have been received at the time at which it would have been delivered in the normal course of post. A notice sent by fax shall be deemed to have been received at the time of transmission (as shown by the timed printout obtained by the sender).</p>
<br />
<strong>13. Governing law and jurisdiction</strong>
<p>13.1 This agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England.</p>
<p>13.2 Each party irrevocably agrees that the courts of England shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with this agreement or its subject matter or formation (including non-contractual disputes or claims).</p>
<br />
<strong>14. Interpretation</strong>
<p>The definitions and rules of interpretation in this clause apply in this agreement.</p>
<br />
<p><strong>Authorised Users:</strong> those governors, trustees, directors, employees and locums of the Customer who have been setup as a user of the Software and Services by the Master User. The term Authorised User shall, for the avoidance of all doubt, include the Master User.</p>
<p><strong>Business Day:</strong> a day other than a Saturday, Sunday or public holiday in England when banks in London are open for business.</p>
<p><strong>Customer:</strong> the entity identified as the customer on the Order Form.</p>
<p><strong>Customer Data:</strong> the identity and/or contact data inputted by the Customer, Authorised Users, or Hub4Leaders on the Customer's behalf for the purpose of using the Services or facilitating the Customer's use of the Services. Customer Data may include information and materials uploaded onto the service, within the secure area designated to the licensed account, by an authorised subscriber of that account. The customer’s views do not reflect the views of H4L.</p>
<p><strong>Data Protection Legislation:</strong> the UK Data Protection Legislation and any other European Union legislation relating to personal data and all other legislation and regulatory requirements in force from time to time which apply to a party relating to the use of personal data (including, without limitation, the privacy of electronic communications); and the guidance and codes of practice issued by the relevant data protection or supervisory authority and applicable to a party.</p>
<p><strong>Documentation:</strong> the documents made available to the Customer by Hub4Leaders via the online Services.</p>
<p><strong>Effective Date:</strong> the later of the dates outlined as the Effective Date in the Order Form.</p>
<p><strong>Federated Trust:</strong> a group of schools that are operating under the governance of a single governing body.</p>
<p><strong>Hub4Leaders:</strong> the trading and brand name of Hub4Leaders Limited, located at The National College, Isaacs Building, 4 Charles Street, Sheffield, S1 2HS.</p>
<p><strong>Initial Subscription Term:</strong> the initial term of this agreement as set out in the Order Form.</p>
<p><strong>Insolvency Event:</strong> means an event where a party is unable to pay its debts (within the meaning of section 123 of the Insolvency Act 1986) or becomes insolvent or an order is made or a resolution passed for the administration, winding-up or dissolution of the other (otherwise than for the purposes of a solvent amalgamation or reconstruction) or an administrative or other receiver, manager, liquidator, administrator, trustee or similar officer is appointed over all or any substantial part of the assets of the other or the other enters into or proposes any composition or arrangement with its creditors generally or any analogous event occurs in any applicable jurisdiction.</p>
<p><strong>LA:</strong> a local authority.</p>
<p><strong>Master User:</strong> the employee, governor, director or trustee of the Customer, the identity of whom is provided in the Order Form (including relevant contact information) who shall be the main point of contact between the Customer and Hub4Leaders and who shall be in control of the Customer’s access to the Services and the Software, including the granting, and managing, of access to the Authorised Users.</p>
<p><strong>MAT/Multi-Academy Trust:</strong> a group of academy schools that have entered into a formal arrangement whereby they agree to share resources for the benefit of the trust members.</p>
<p><strong>Content Suggestion Service:</strong> requests can be submitted by Master Users only. Hub4Leaders will respond to a selection of requests depending on available resources, its ability to assist and relevant other factors. A response may consist of, for example, an answer to a question raised, a model document or a reference to a resource where the Customer can obtain further information. Responses and model documents are intended for use as guidance only. Schools should use any document provided as a basis for the creation of their own final document, complete with necessary approval.</p>
<p><strong>Normal Business Hours:</strong> 9.00 am to 5.00 pm local UK time, each Business Day.</p>
<p><strong>Order Form:</strong> the order form, both physical and on-line represents the agreement of the Customer to purchase the Service from Hub4Leaders.</p>
<p><strong>Referral Scheme Terms and Conditions:</strong> the terms and conditions that apply to the referral by a Customer of another School or another School in the Multi-Academy Trust that the Customer belongs to.</p>
<p><strong>Renewal Period:</strong> has the meaning provided to it in clause 2.2.</p>
<p><strong>Services:</strong> the subscription services provided by Hub4Leaders to the Customer under this agreement via online Services to the Customer by Hub4Leaders from time to time, as more particularly described in the Documentation.</p>
<p><strong>Software:</strong> the online software applications provided by Hub4Leaders as part of the Services.</p>
<p><strong>Subscription Fees:</strong> the subscription fees payable by the Customer to Hub4Leaders for the User Subscriptions, as set out in the Order Form.</p>
<p><strong>Subscription Term:</strong> means the Initial Subscription Term and any Renewal Period.</p>
<p><strong>Terms:</strong> means the terms and conditions set out in this document.</p>
<p><strong>UK Data Protection Legislation:</strong> all applicable data protection and privacy legislation in force from time to time in the UK including the General Data Protection Regulation ((EU) 2016/679); the Data Protection Act 2018; the Privacy and Electronic Communications Directive (2002/58/EC) (as updated by Directive 2009/136/EC) and the Privacy and Electronic Communications Regulations 2003 (SI 2003/2426) as amended.</p>
<p><strong>User Subscription:</strong> the user subscription purchased by the Customer which entitles Authorised Users to access and use the Services and the Documentation in accordance with this agreement.</p>
<p><strong>User Support Services:</strong> Hub4Leaders’s support in relation to the Services to help with access to the Services; password reset; help and guidance with features and functionality of the Services; demonstrations of the Services; Customer Compliance Manager assistance; Continual Professional Development courses; and help with renewal subscriptions.</p>
<p><strong>Virus:</strong> anything or device (including any software, code, file or programme) which may: prevent, impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any programme or data, including the reliability of any programme or data (whether by re-arranging, altering or erasing the programme or data in whole or part or otherwise); or adversely affect the user experience, including worms, trojan horses, viruses and other similar things or devices.</p>
<p><strong>Website:</strong> the URL notified to the Customer as being the website that hosts the Services.</p>
<p><strong>Weekly Newsletter:</strong> a weekly newsletter explaining all the latest updates in education and challenges and highlighting solutions available in the Services.</p>
