import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { SearchService } from '../../search/search.service';

@Component({
    selector: 'header-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    searchFilter: string = "";
    autocompleteResults: any = [];
    candidateResults: any;
    searchPlaceholder = 'Search';
    apiKey: string;

    searchText: string = "";
    isLiveEnvironment: boolean;
    showPopularSearchResults: boolean;
    popularSearches;

    constructor(
        private searchService: SearchService,
        private router: Router,
        private element: ElementRef
    ) { }

    ngOnInit(): void {
        this.searchService.getSearchConfig().subscribe(config => {
            this.apiKey = config.Code;
            this.isLiveEnvironment = config.EnvironmentLive;
        });

        this.searchService.getPopularSearchItemList()
            .subscribe(results => {
                this.popularSearches = results;
            })
    }

    @HostListener('document:click', ['$event'])
    clickout(event: Event) {
        if (!this.element.nativeElement.contains(event.target)) {
            this.hideAutoComplete();
        }
    }

    search(searchResult) {
        if (this.searchText != "") {
            this.router.navigateByUrl('/search?search=' + encodeURIComponent(this.searchText) + '&filter=' + this.searchFilter);
            this.searchService.viewSelectedMobileResult$.next(true);
            this.hideAutoComplete();
        }

        if (searchResult != undefined && searchResult.Url) {
            this.autocompleteResults = [];
            this.router.navigateByUrl(searchResult.Url);
            this.searchService.viewSelectedMobileResult$.next(true);
        }
    }

    searchBarClicked() {
        this.showPopularSearches();
    }

    searchTextChanged(event) {
        if (event.which == 13) {
            this.search(undefined);
            this.hideAutoComplete();
        } else if (event.which != undefined) {
            if (this.searchText.length <= 2) {
                this.showPopularSearches();
            } else if (this.searchText.length > 2) {
                this.searchService.getSearchSuggestions(this.searchText, this.searchFilter, this.apiKey, this.isLiveEnvironment)
                    .subscribe(x => this.showAutoComplete(x),
                        error => {
                            console.log(error);
                        }
                    );
            } else {
                this.hideAutoComplete();
            }
        }
    }

    searchFocused(isFocused: boolean): void {
        this.searchPlaceholder = this.searchText == '' && !isFocused ? 'Search' : '';
        this.searchTextChanged(-1);
        if (isFocused && this.searchText.length > 2) {
            window.setTimeout(() => {
                this.searchService.getSearchSuggestions(this.searchText, this.searchFilter, this.apiKey, this.isLiveEnvironment)
                    .subscribe(x => this.showAutoComplete(x));
            }, 0);
        }
    }

    showPopularSearches() {
        this.showPopularSearchResults = true;
    }

    hidePopularSearches() {
        this.showPopularSearchResults = false;
    }

    showAutoComplete(results) {
        this.autocompleteResults = results.autoComplete;
        while (this.autocompleteResults.length > 3) {
            this.autocompleteResults.pop();
        }

        results.searchResults.forEach(x => x.description = this.limitText(x.description, 800))
        this.candidateResults = results.searchResults;
    }

    suggestionsStyle() {
        let currentWidth = Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );

        let css = { 'max-width': (currentWidth - 400) + 'px' };
        return css;
    }

    limitText(text, limit) {
        if (text.length < limit) {
            return text;
        } else {
            return text.substring(0, limit - 3) + '...';
        }
    }

    hideAutoComplete() {
        this.autocompleteResults = [];
        this.candidateResults = [];
        this.showPopularSearchResults = false;
    }

    newOrUpdated(content) {
        let isNew = (createdDateString: string): boolean => {
            let today = new Date();
            let testDate = new Date(createdDateString);
            testDate.setDate(testDate.getDate() + 21);

            return testDate > today;
        },
            isUpdated = (updatedDateString: string, createdDateString: string): boolean => {
                let today = new Date();
                let testDate = new Date(updatedDateString);
                testDate.setDate(testDate.getDate() + 21);

                return (testDate > today) && !isNew(createdDateString);
            };

        return isNew(content.createdDate) ? 'New' :
            isUpdated(content.updatedDate, content.createdDate) ? 'Updated' : '';
    }

    selectSuggestion(searchResult) {
        this.autocompleteResults = [];
        this.candidateResults = [];
        this.hidePopularSearches();
        this.searchText = searchResult;
        this.search(this.searchText);
    }

    viewResultCandidate(content) {
        this.resetSearchBar();
        this.searchService.viewSelectedMobileResult$.next(true);
        this.router.navigateByUrl(content.url);
    }

    resetSearchBar() {
        this.searchText = '';
        this.autocompleteResults = [];
        this.candidateResults = [];
        this.searchFilter = '';
        this.searchPlaceholder = 'Search';
        document.getElementById("mobile-search").style.width = "0";
    }

    truncatedDescription(description) {
        if (description.length > 80) {
            return description.substring(0, 77) + '...';
        } else {
            return description;
        }
    }

}
