<div>
    <div class="mobile-nav-icon-group">
        <button>
            <img src="../../../../Img/icons/material/menu.png" alt="Mobile navigation menu button" width="22" *ngIf="!mobileNavVisible" (click)="toggleMobileNav()">
            <img src="../../../../Img/icons/material/close.png" alt="Mobile navigation close button" width="22" *ngIf="mobileNavVisible" (click)="toggleMobileNav()">
        </button>
        <button>
            <span *ngIf="!searchVisible" (click)="toggleSearch()"><img src="../../../../Img/icons/material/search_navy.svg" alt="Mobile search button" width="22"></span>
            <span *ngIf="searchVisible" (click)="toggleSearch()"><img src="../../../../Img/icons/material/close_navy.svg" alt="Mobile search close button" width="22"></span>
        </button>
    </div>

    <div *ngIf="mobileNavData">
        <div id="main-mobile-nav" class="mobile-nav-panel">
            <div *ngIf="mobileNavVisible && !searchVisible">
                <div class="mobile-nav-header-group">
                    <h2>MENU</h2>
                </div>
                <div *ngFor="let mainNavItem of mobileNavData.HeaderToolbars; let i = index" class="mobile-nav-item">
                    <a *ngIf="mainNavItem.IsExternalLink" [href]="mainNavItem.LinkUrl" [target]="mainNavItem.Target || '_blank'">
                        <img src="../../../../Img/icons/material/{{mainNavItem.IconRef}}.png" class="mobile-material-image"/>
                        {{mainNavItem.DisplayText}}
                        <img src="../../../../Img/icons/material/chevron_right.png" class="mobile-chevron">
                    </a>
                    <a *ngIf="!mainNavItem.IsExternalLink" (click)="openSubMobileNav(mainNavItem)">
                        <img src="../../../../Img/icons/material/{{mainNavItem.IconRef}}.png" class="mobile-material-image" />
                        {{mainNavItem.DisplayText}}
                        <img src="../../../../Img/icons/material/chevron_right.png" class="mobile-chevron">
                    </a>
                </div>
            </div>
        </div>
        <div id="mobile-search" class="mobile-nav-panel">
            <div *ngIf="!mobileNavVisible && searchVisible">
                <header-search></header-search>
            </div>
        </div>
        <div id="sub-mobile-nav" class="mobile-nav-panel sub-menu">
            <div class="mobile-nav-header-group">
                <button (click)="mobileNavBack()"><img src="../../../../Img/icons/material/chevron_left.png" alt="Mobile navigation back button" width="22"></button>
                <h2>{{subNavTitle | uppercase}}</h2>
            </div>
            <div *ngFor="let subNavItem of subNavList">
                <div *ngIf="subNavItem.IsExternalLink">
                    <a [href]="subNavItem.LinkUrl" [target]="subNavItem.Target || '_blank'">
                        <div>{{subNavItem.Title}}</div>
                    </a>
                </div>
                <div *ngIf="!subNavItem.IsExternalLink">
                    <a [routerLink]="getLink(subNavItem.LinkUrl)" [queryParams]="getQueryParams(subNavItem.LinkUrl)" (click)="closeSubMobileNav()">
                        <div>{{subNavItem.Title}}</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
