import { Component, OnInit } from '@angular/core';
import { HeaderService } from './header.service';
import { Router, NavigationStart } from '@angular/router';
import { FormsModule, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { AuthService } from '../guards/auth.service';
import { SearchService } from '../search/search.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AutologinService } from '../auto-login/autologin.service';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [SearchService]
})
export class HeaderComponent implements OnInit  {
    headerData: any;
    memberText: string;
    memberButtonUrl: string;
    showMemberButton: boolean = true;
    hideHeader: boolean = false;
    headerOverlayable: boolean = false;

    constructor(
        private headerService: HeaderService,
        private authService: AuthService,
        private location: Location,
        private autologinService: AutologinService,
        private meta: Meta) {
    }

    ngOnInit() {
        this.headerService.hideHeader$.subscribe(value => {
            this.hideHeader = value
            if (this.hideHeader) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        });
        this.authService.loggedIn$.subscribe(() => {
            this.setHeaderData();
        });
        const domain = window.location.origin;

        this.meta.updateTag({ content: `${domain}/Img/icons/tsb-meta-logo.png`, }, 'property="og:image"');
        this.authService.isSaving$.subscribe(isSaving => this.headerOverlayable = isSaving);
    }

    setHeaderData(): void {
        this.headerService.getHeader().subscribe(data => {
            this.headerData = data;
            this.setButtonVisibility();

            this.authService.loggedIn$.subscribe(() => {
                this.setButtonVisibility();
            });
        });
    }

    setButtonVisibility(): void {
        if (!this.authService.authenticated) {
            this.memberText = 'BECOME A MEMBER';
            this.memberButtonUrl = 'membership'
        } else if (this.authService.authenticated && this.authService.showNCPurchaseOption) {
            this.memberText = 'UPGRADE MEMBERSHIP';
            this.memberButtonUrl = 'nationalcollege';
        } else {
            this.showMemberButton = false;
        }
    }
}
