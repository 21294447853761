<div class="content-blocker-container">
    <div class="content-blocker-content-left">
        <img src="../../../Img/icons/material/Locked-Content_icon.png"/>
        <p>CONTENT LOCKED</p>
        <p>{{leftContent.textOne}} <span class="orange-sentence">{{leftContent.textTwoOrange}}</span> {{leftContent.textThree}}<span class="bold-sentence">{{leftContent.textFourBold}}</span></p>
    </div>
    <div class="content-blocker-content-right">
        <div class="title-group">
            <h3>The School Bus provides expert-led advice </h3>
            <h3 class="orange-title">and time-saving templates for School Leaders</h3>
        </div>
        <div class="bullets-group">
            <div class="bullet"><img src="../../../../Img/icons/material/check_circle_orange.png" /><p>Meet and evidence statutory policy and compliance requirements</p></div>
            <div class="bullet"><img src="../../../../Img/icons/material/check_circle_orange.png" /><p>Be the first to understand the latest changes and how to manage them effectively</p></div>
            <div class="bullet"><img src="../../../../Img/icons/material/check_circle_orange.png" /><p>Guaranteed to have every time-saving template you will ever need</p></div>
            <div class="bullet"><img src="../../../../Img/icons/material/check_circle_orange.png" /><p>Underpinned by trusted experts and world-renowned institutions</p></div>
        </div>
        <p>Trusted by thousands of schools, academies and trusts </p>
        <div class="button-group">
            <a routerLink="/membership" class="btn-orange"><span class="member-image"></span>BECOME A MEMBER</a>
            <a class="btn btn-ghost" href="/marketing/freedemo" target="_blank">Take a Guided Tour  <span class="arrow"></span></a>
        </div>
    </div>
</div>
