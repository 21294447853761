import { Component, OnInit, ViewEncapsulation, ViewChild, Renderer2 } from '@angular/core';
import { TandCModalComponent } from './modal/tandc/tandc-modal.component';
import { TrialModal } from './modal/trial/trial-modal.component';
import { RedirectToHomeComponent } from './redirect/redirect-to-home.component';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { Location, NgClass } from '@angular/common';
import { Cookie } from 'ng2-cookies';

import { MatDialog } from '@angular/material/dialog';
import { BreadcrumbService } from './components/breadcrumb/breadcrumb.service';
import { AuthService } from './guards/auth.service';
import { HeaderService } from './header/header.service';
import { Subscription ,  interval } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { LogoutComponent } from './login/logout.component';
import { filter, timeout } from 'rxjs/operators';
import { CookieAcceptancePopupComponent } from './legal/cookies/cookie-acceptance-popup/cookie-acceptance-popup.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'body',
    templateUrl: 'app.component.html',
    styleUrls: ['./components/material/tsb-theme.scss', './app.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [BreadcrumbService, HeaderService]
})
export class AppComponent implements OnInit {
    isTrial: boolean;
    redirectToYork: boolean = false;
    authSubscription: Subscription;
    isLoggedIn: boolean;
    bodyClass: string = "";
    menuList: any;
    isAdmin: boolean = false;
    showFooter: boolean = true;
    routerWrapper: string;
    pageTitle: string;

    cookieAcceptanceSubject

    @ViewChild('sidenav') sidenav: any;

    public setBodyClass(newClass: string) {
        this.bodyClass = newClass;
    }

    constructor(
        private router: Router,
        private loc: Location,
        private dialog: MatDialog,
        private breadcrumb: BreadcrumbService,
        private route: ActivatedRoute,
        private auth: AuthService,
        private headerService: HeaderService,
        private renderer: Renderer2,
        private title: Title,
        updates: SwUpdate 
    ) {
        this.setupAcceptanceCookie();
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd))                
            .subscribe((event: NavigationEnd) => {
                this.breadcrumb.AddRoute(event.url, this.route.snapshot.queryParams);

                let canonical = document.querySelector('link[rel="canonical"]');
                this.renderer.setAttribute(canonical, 'href', window.location.href);

                if (this.router.url.startsWith('/trial')) {
                    this.bodyClass = 'trialv3'
                }
                else if (this.router.url.startsWith('/register')) {
                    this.bodyClass = 'register'
                }
                else if (this.router.url.startsWith('/compliancemanager')) {
                    this.bodyClass = 'standard';
                }
                else {
                    this.bodyClass = 'standard';
                }

                if (this.auth.requiresPasswordChange &&
                    this.loc.path() != '' &&
                    this.loc.path() != '/logout' &&
                    this.loc.path() != '/login' &&
                    this.loc.path() != '/account/changepassword') {
                    this.router.navigate(['account', 'changepassword']);
                    return;
                }

                if (this.auth.loggedIn && auth.tsbDateTandCaccepted == null || !this.auth.acceptedTsbTandC) {
                    if (this.loc.path() != '' &&
                        this.loc.path() != '/logout' &&
                        this.loc.path() != '/login' &&
                        this.loc.path() != '/account/changepassword') {

                        let dialogue = this.dialog.open(TandCModalComponent, { disableClose: true });
                        dialogue.afterClosed().subscribe(() => {
                            if (this.auth.pageTitle != null && this.auth.pageTitle != "") {
                                this.title.setTitle(this.auth.pageTitle);
                            }
                        });
                    }
                }

                if (this.auth.hasRole("TrialUser") && this.loc.path() != '/account/changepassword') {
                    if (Cookie.get("TSB_Trial_Session") != String(auth.userId)) {

                        this.dialog.open(TrialModal, { disableClose: true })
                        //const trialModal = this.modalService.open(TrialModal, { backdrop: 'static' });
                        Cookie.set("TSB_Trial_Session", String(auth.userId), 0.25);   // cookie should expire in 6h (0.25 days)
                    }
                }
            });
        this.auth.showFooter$.subscribe(x => this.showFooter = x);
    }

    ngOnInit(): void {
        if (!this.redirectToYork) {
            this.authSubscription = this.auth.loggedIn$.subscribe(item => {
                this.update();
            });
        }

        this.auth.showFooter$.subscribe(show => this.routerWrapper = show ? 'router-wrapper' : 'router-wrapper-no-footer');

        if (this.loc.path() == '/trial')
        {
            this.isTrial = true
        }

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.sidenav.close();
            }
        });

        let canonical = document.querySelector('link[rel="canonical"]');
        this.renderer.setAttribute(canonical, 'href', window.location.href);

        this.setupThirdPartyScripts();
    }

    ngOnDestroy() {
        // prevent memory leak when component is destroyed
        this.authSubscription.unsubscribe();
    }

    update(): void {
        this.isLoggedIn = this.auth.loggedIn;
    }

    onActivate(event) {
        window.scroll(0, 0);
        const bodyEl = document.getElementById('body-content');
        if (bodyEl) {
            bodyEl.scroll(0, 0);
        }
    }

    menuToggle(toggle: boolean): void { 
        this.sidenav.toggle();
    }

    menuItemUpdate(menuList: any): void {
        this.menuList = menuList;
    }

    setupAcceptanceCookie() {
        let cookiePolicyAcceptance = Cookie.get('cookie_policy_acceptance');
        if (cookiePolicyAcceptance.length == 0) {
            this.dialog.open(CookieAcceptancePopupComponent, {
                disableClose: true,
                hasBackdrop: false,
                position: {
                    bottom: '40px',
                    left: '40px'
                },
                panelClass: 'cookie-panel-class'
            });
        }
    }

    setupThirdPartyScripts() {
        let cookiePolicyAcceptance = () => Cookie.get('cookie_policy_acceptance').split('|').filter(x => x != '');
        let setupScriptsWhenPreferencesSet = () => {
            if (cookiePolicyAcceptance().filter(x => x == 'all' || x == 'analytics').length > 0) {
                this.setupAnalyticsScripts();
            }
            if (cookiePolicyAcceptance().filter(x => x == 'all' || x == 'marketing').length > 0) {
                this.setupMarketingScripts();
            }
        }
        if (cookiePolicyAcceptance().length == 0) {
            this.auth.cookiePreferncesSet.subscribe(preferencesSet => {
                if (preferencesSet) {
                    let acceptanceList = cookiePolicyAcceptance().join('|');
                    
                    this.deleteCookies();
                    Cookie.set('cookie_policy_acceptance', acceptanceList, 365);
                    setupScriptsWhenPreferencesSet();
                }
            });
        } else {
            setupScriptsWhenPreferencesSet();
        }
    }

    deleteCookies() {
        let cookies = Cookie.getAll();

        console.log('cookie list');
        for (let cookie in cookies) {
            console.log(cookie);
            Cookie.delete(cookie);
            Cookie.delete(cookie, '/', `.${window.location.host.replace('www.', '')}`);
        }

        let whatsLeft = Cookie.getAll();
        console.log(whatsLeft);
    }

    setupAnalyticsScripts() {
        if (this.auth.isLive && this.auth.loggedIn) {
            this.addHotjarScript();
        }

        this.addGoogleScript();
        this.addCloudFlare();
    }

    setupMarketingScripts() {
        this.addSleeknoteScript(9803);
        this.addPixelScript();
        this.addForce24Script();
    }

    addHotjarScript(): void {
        if (this.loc.path() == '/home') {
            document.getElementById('hotjarScript').textContent = "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:1194444,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";
        }
    }

    addGoogleScript(): void {
        let flag = document.getElementById('googleScript');

        this.addGoogleAnalytics(flag);
        this.addGoogleTagManager(flag);

        flag.remove();
    }

    addCloudFlare(): void {
        let flag = document.getElementById('cloudFlareScript');

        flag.after(document.createComment('End Cloudflare Web Analytics'));
        let script = document.createElement('script');
        script.defer = true;
        script.src = 'https://static.cloudflareinsights.com/beacon.min.js';
        script.attributes.setNamedItem(document.createAttribute('data-cf-beacon'));
        script.attributes.getNamedItem('data-cf-beacon').value = '{"token": "bd1cb983634f4210b6a0f89007a3923b"}'
        flag.after(script);
        flag.after(document.createComment('Cloudflare Web Analytics'));

        flag.remove();
    }

    addGoogleTagManager(flag: HTMLElement) {
        let script = document.createElement('script');
        script.innerText = "(function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-PS938LM');";

        flag.after(document.createComment('End Google Tag Manager'));
        flag.after(script);
        flag.after(document.createComment('Google Tag Manager'));
    }

    addGoogleAnalytics(flag: HTMLElement): void {
        let script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-VJDK0HZNDW';
        let script2 = document.createElement('script');
        script2.innerText = "window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'G-VJDK0HZNDW');";
        flag.after(script2);
        flag.after(script);
        flag.after(document.createComment('Global site tag (gtag.js) - Google Analytics'));
    }

    addPixelScript(): void {
        let flag = document.getElementById('pixelScript');

        let script = document.createElement('script');
        script.textContent = "!function (f, b, e, v, n, t, s) { if (f.fbq) return; n = f.fbq = function () { n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments) }; if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0; t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s) }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '1334745076735446'); fbq('track', 'PageView');"
        let noscript = document.createElement('noscript');
        let noscriptImg = document.createElement('img');
        noscriptImg.height = 1;
        noscriptImg.width = 1;
        noscriptImg.style.display = 'none';
        noscriptImg.src = 'https://www.facebook.com/tr?id=1334745076735446&ev=PageView&noscript=1'
        noscript.appendChild(noscriptImg);

        flag.after(document.createComment('End Facebook Pixel Code'));
        flag.after(noscript);
        flag.after(script);
        flag.after(document.createComment('Facebook Pixel Code'));

        flag.remove();
    }

    addForce24Script(): void {
        let flag = document.getElementById('force24');

        let script = document.createElement('script');
        script.textContent = `
    (function (f, o, r, c, e, _2, _4) {
        f.Force24Object = e, f[e] = f[e] || function () {
            f[e].q = f[e].q || [], f[e].q.push(arguments)
        }, f[e].l = 1 * new Date, _2 = o.createElement(r),
            _4 = o.getElementsByTagName(r)[0], _2.async = !0, _2.src = c, _4.parentNode.insertBefore(_2, _4)
    })(window, document, "script", "https://static.websites.data-crypt.com/scripts/activity/v3/inject-v3.min.js", "f24");
        f24('config', 'set_tracking_id', '66d6ace3-c486-450f-a8f0-08b01a127f2f');
    f24('config', 'set_client_id', '5716a644-3724-444a-b3cf-23c01190620a');
        `;

        flag.after(document.createComment('End Force24 Tracking'));
        flag.after(script);
        flag.after(document.createComment('Force24 Tracking'));

        flag.remove();
    }

    addSleeknoteScript(scriptNo: number): void {
        let scriptTag = document.getElementById('sleeknoteScript');
        let sleeknoteInjectedScriptTag = document.createElement('script');
        sleeknoteInjectedScriptTag.type = 'text/javascript';
        sleeknoteInjectedScriptTag.charset = 'utf-8';
        sleeknoteInjectedScriptTag.src = (`//sleeknotecustomerscripts.sleeknote.com/${scriptNo}.js`);
        scriptTag.parentNode.insertBefore(sleeknoteInjectedScriptTag, scriptTag);
    }
}
