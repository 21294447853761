<div id="toolbar-and-button">
    <div id="toolbar-container">
        <ng-container *ngIf="toolbarData">
            <div *ngFor="let menu of toolbarData; let i = index" [class.no-final-separator]="!showMemberButton" [class.hide-on-full-width]="!menu.FullWidthVisible" class="toolbar-items" (mouseleave)="closeMenu(menu, i)">
                <div class="tab-label" mat-menu-trigger (mouseover)="openMenu(menu, i)">
                    <a *ngIf="!menu.IsExternalLink" [routerLink]="getLink(menu.LinkUrl)" [queryParams]="getQueryParams(menu.LinkUrl)" (click)="openMenu(menu, i)">
                        <span class="material-image" [style.background-image]="materialImage(menu.IconRef)">
                        </span>
                        {{menu.DisplayText | uppercase}}
                    </a>
                    <a *ngIf="menu.IsExternalLink && !menu.IsNationalCollegeLink" [href]="menu.LinkUrl" [target]="menu.Target || '_blank'">
                        <span class="material-image" [style.background-image]="materialImage(menu.IconRef)">
                        </span>
                        {{menu.DisplayText | uppercase}}
                    </a>
                    <a *ngIf="menu.IsNationalCollegeLink" (click)="tncAutoLogin()">
                        <span class="material-image" [style.background-image]="materialImage(menu.IconRef)">
                        </span>
                        {{menu.DisplayText | uppercase}}
                    </a>
                </div>
                <div class="hidden-menu menu-container">
                    <ul>
                        <li *ngIf="menu.DisplayText == 'Tools' && pwaReady">
                            <div>
                                <a (click)="installPWA()">
                                    <div class="tool-item-title">Web App</div>
                                    <div class="tool-item-description">Install our app and get faster access to The School Bus</div>
                                </a>
                            </div>
                        </li>
                        <li *ngFor="let item of menu.HeaderToolbarItems">
                            <div *ngIf="item.IsExternalLink">
                                <a [href]="item.LinkUrl" [target]="item.Target || '_blank'">
                                    <div class="tool-item-title">{{item.Title}}</div>
                                    <div class="tool-item-description">{{item.ItemDescription}}</div>
                                </a>
                            </div>
                            <div *ngIf="!item.IsExternalLink">
                                <a [routerLink]="getLink(item.LinkUrl)" [queryParams]="getQueryParams(item.LinkUrl)" (click)="closeMenu(menu, i)">
                                    <div class="tool-item-title">{{item.Title}}</div>
                                    <div class="tool-item-description">{{item.ItemDescription}}</div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </ng-container>

    </div>
    <a class="btn btn-orange" *ngIf="showMemberButton" [routerLink]="memberButtonUrl">
        <span class="member-image">
        </span>
        {{memberText}}
    </a>
</div>
